import axios, { AxiosError, RawAxiosRequestConfig } from 'axios';
import {
  Dealer,
  DealersResponse,
  MachineConfiguration,
  SerialMachineData,
  SortCropsBody,
} from '@mygrimme/types';
import { CropFormData } from '~/pages-components/crops/utils';
import { GrimmeApiCrop } from '~/pages-components/crops/utils/types';
import { GetWearPartsResponse } from '~/pages-components/machine-details/hooks/types';
import { defaultLocale } from '@/lib/i18n/i18n.config';
import { getCachedAxiosInstance } from '../../lib/axios';
import {
  ApiCalls,
  APIError,
  CallApiProps,
  CCITokenResponse,
  ErrorBody,
  MachineConfigurationObject,
  NetworkError,
  TelemetryDataBySerial,
} from './ApiQueryTypes';
import { environmentConstants } from './config';
import { DEFAULT_COUNTRY_CODE, DEFAULT_DEALER } from './consts';

export const createApiUrl = (path: string) => {
  const apiUrl = environmentConstants.apiUrl;
  path = path[0] === '/' ? path : `/${path}`;
  return new URL(`${apiUrl}${path}`);
};

export const callApi = async <Result, Body = undefined>({
  accessToken,
  body = undefined,
  endpoint,
  id,
  language = 'de',
  method = 'get',
  responseType = 'json',
}: CallApiProps<Body>) => {
  const url = new URL(endpoint.toString());

  // TODO: Remove caching completely
  const axios = getCachedAxiosInstance();

  if (language) {
    url.searchParams.set('language', language);
  }

  const headers: Record<string, string> = {
    'Content-type': 'application/json',
  };

  try {
    if (!accessToken) {
      throw new Error('No access token');
    }
    headers['x-access-token'] = accessToken;
    headers['Authorization'] = `Bearer ${accessToken}`;
  } catch (error) {
    console.error(typeof error, { error });
  }

  let response;

  if (axios) {
    try {
      response = await axios({
        cache: false,
        data: JSON.stringify(body),
        headers,
        id,
        method,
        responseType: responseType,
        url: url.toString(),
      });
    } catch (error) {
      const axiosError = error as AxiosError<AxiosError>;

      if (axiosError.response?.status && axiosError.response?.status > 400) {
        throw new APIError(axiosError.response.data);
      } else {
        throw new NetworkError(error);
      }
    }
  }

  // no content
  if (response?.status === 204) {
    return;
  }

  return response?.data as Result;
};

// body is extended since compiler thinks its React due to tsx
export const callApiBinary = async <Body,>({
  accessToken,
  body,
  endpoint,
  method = 'get',
}: Omit<CallApiProps<Body>, 'language'>): Promise<string | undefined> => {
  const url = new URL(endpoint.toString());

  const headers: HeadersInit | undefined = {
    'content-type': 'application/json',
  };
  try {
    if (!accessToken) {
      throw new Error('No access token');
    }
    headers['x-access-token'] = accessToken;
    headers['Authorization'] = `Bearer ${accessToken}`;
  } catch (error) {
    console.error(typeof error, { error });
  }

  const response = await fetch(url, {
    body: JSON.stringify(body),
    headers,
    method,
  });
  const resBody = await (response.blob() as Promise<Blob | MediaSource>);

  if (response.status >= 400) {
    throw new APIError(resBody as ErrorBody);
  }

  try {
    return URL.createObjectURL(resBody);
  } catch (error) {
    console.error({ error });
  }
};

export const getDocumentsBySerialApiUrl = (serial: string, language: string) =>
  createApiUrl(`machines/documents?serial=${serial}&language=${language}`);

export const getTelemetryBySerialApiUrl = (serial: string, language: string) =>
  createApiUrl(`telemetry?serials=${serial}&countryId=${language}`);

const apiCalls: ApiCalls = {
  createCrop: async (accessToken: string | undefined, body: CropFormData) => {
    const endpoint = createApiUrl('crops');
    return callApi({
      accessToken,
      body,
      endpoint,
      id: 'createCrop',
      method: 'post',
    });
  },

  deleteCrop: async (
    accessToken: string | undefined,
    cropType: number,
    cropVarietyId: number,
  ) => {
    const endpoint = createApiUrl(
      `crops?cropType=${cropType}&cropVarietyId=${cropVarietyId}`,
    );
    return callApi({
      accessToken,
      endpoint,
      id: 'deleteCrop',
      method: 'delete',
    });
  },

  downloadDocument: async (
    docId: string,
    encodedDocumentHash: string,
    filename = 'daten.pdf',
    accessToken: string,
  ) => {
    const endpoint = createApiUrl(
      `machines/download?docId=${docId}&encodedDocumentHash=${encodedDocumentHash}&filename=${filename}`,
    );
    const objectUrl = await callApiBinary({
      accessToken,
      endpoint,
      id: 'downloadDocument',
    });

    // create in-memory a tag and simulate click to download
    const link = document.createElement('a');
    link.href = objectUrl as string;
    link.download = filename;
    // some browser needs the anchor to be in the doc
    document.body.append(link);
    link.click();
    link.remove();
    // in case the Blob uses a lot of memory
    setTimeout(() => URL.revokeObjectURL(link.href), 7000);
  },

  getCCIAuthToken: async (sessionCode: string, accessToken: string) => {
    const endpoint = createApiUrl(`cci/token?sessionCode=${sessionCode}`);
    return callApi<CCITokenResponse>({
      accessToken,
      endpoint,
      id: 'getCCIAuthToken',
    });
  },

  getCrops: async (accessToken: string, bussinessRelationId: string) => {
    const endpoint = createApiUrl(
      `crops?businessRelationId=${bussinessRelationId}`,
    );
    const crops = await callApi<GrimmeApiCrop[]>({
      accessToken,
      endpoint,
      id: 'getCrops',
    });
    return crops || [];
  },

  getDealerById: async (id: string, accessToken: string) => {
    const endpoint = createApiUrl(`dealers/${id}`);
    const dealer = await callApi<Dealer>({
      accessToken,
      endpoint,
      id: 'getDealerById',
    });
    return dealer;
  },

  getDealersV2: async (
    countryCode = DEFAULT_COUNTRY_CODE,
    language = defaultLocale,
    accessToken: string,
  ) => {
    const endpoint = createApiUrl('dealers/v2');
    // language is appended in callApi
    endpoint.searchParams.append('countryCode', countryCode);
    const dealers =
      (await callApi<DealersResponse>({
        accessToken,
        endpoint,
        id: 'getDealersV2',
        language,
      })) || [];
    if (dealers?.length === 0) {
      dealers.push(DEFAULT_DEALER);
    }
    return dealers;
  },

  getGeolocation: async (
    language: string,
  ): Promise<Geolocation | undefined> => {
    const threeDays = 1000 * 60 * 60 * 24 * 3;
    const curLocation = localStorage.getItem('geolocation');
    const dateLocation = localStorage.getItem('geolocation-date');

    if (curLocation && Number(dateLocation) + threeDays > Date.now()) {
      return JSON.parse(curLocation);
    }

    const endpoint = createApiUrl('geolocation');
    const location = await callApi<Geolocation>({
      endpoint,
      id: 'getGeolocation',
      language,
    });

    localStorage.setItem('geolocation', JSON.stringify(location));
    localStorage.setItem('geolocation-date', Date.now().toFixed(0));

    return location;
  },

  /** @deprecated */
  getMachineBySerial: async (
    serial: string,
    language: string,
    accessToken: string,
  ): Promise<SerialMachineData> => {
    const endpoint = createApiUrl(`machines/${serial}`);
    const machine = await callApi<SerialMachineData>({
      accessToken,
      endpoint,
      id: 'getMachineBySerial',
      language,
    });

    return machine as SerialMachineData;
  },

  getMachineConfigurationBySerial: async (
    serial: string,
    language: string,
    accessToken: string,
  ): Promise<MachineConfigurationObject> => {
    const endpoint = createApiUrl(`machines/${serial}/configuration`);
    const configuration = await callApi<MachineConfiguration[]>({
      accessToken,
      endpoint,
      id: 'getMachineConfigurationBySerial',
      language,
    });
    return { configuration: configuration || [], serial };
  },

  getTelemetryData: async (
    serials: string[],
    accessToken: string,
    country?: string,
  ): Promise<TelemetryDataBySerial | undefined> => {
    const endpoint = createApiUrl('telemetry');
    serials?.forEach((serial) => {
      endpoint.searchParams.append('serials', serial);
    });
    if (country) {
      endpoint.searchParams.append('country', country);
    }

    return callApi<TelemetryDataBySerial>({
      accessToken,
      endpoint,
      id: 'getTelemetryData',
    });
  },

  getWearParts: async (
    accessToken: string,
    serialnumber: string,
    language: string,
  ) => {
    const endpoint = createApiUrl(
      `/wear-parts/${serialnumber}?language=${language}`,
    );

    return callApi<GetWearPartsResponse>({
      accessToken,
      endpoint,
      id: 'getWearParts',
      language: language,
    });
  },

  getWearPartsExcel: async (
    accessToken: string,
    serialnumber: string,
    language: string,
  ) => {
    const endpoint = createApiUrl(
      `/wear-parts/${serialnumber}/excel?language=${language}`,
    );

    return callApi<Blob>({
      accessToken,
      endpoint,
      id: 'getWearPartsExcel',
      language,
      responseType: 'blob',
    });
  },

  sortCrop: async (
    accessToken: string,
    body: SortCropsBody,
    cropType: number,
  ) => {
    const endpoint = createApiUrl(`/crops/sort/${cropType}`);
    return callApi({
      accessToken,
      body,
      endpoint,
      id: 'sortCrop',
      method: 'put',
    });
  },

  updateCrop: async (accessToken: string | undefined, body: CropFormData) => {
    const endpoint = createApiUrl('crops');
    return callApi({
      accessToken,
      body,
      endpoint,
      id: 'updateCrop',
      method: 'put',
    });
  },
};

export const getTelemetryDataV2 = async (
  params: { serials: string[]; country?: string },
  config?: RawAxiosRequestConfig,
) => {
  const endpointUrl = createApiUrl('telemetry');

  params.serials?.forEach((serial) => {
    endpointUrl.searchParams.append('serials', serial);
  });

  if (params.country) {
    endpointUrl.searchParams.append('country', params.country);
  }

  return axios.get<TelemetryDataBySerial>(endpointUrl.href, config);
};

export const {
  createCrop,
  deleteCrop,
  downloadDocument,
  getCCIAuthToken,
  getCrops,
  getDealerById,
  getDealersV2,
  getGeolocation,
  getMachineBySerial,
  getMachineConfigurationBySerial,
  getTelemetryData,
  getWearParts,
  getWearPartsExcel,
  sortCrop,
  updateCrop,
} = apiCalls;
